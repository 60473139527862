#page-ressources {
  .txt-image-blocks{
    padding-top:95px;
    &:nth-of-type(odd){
      .container{
        flex-direction: row-reverse;
        @media(max-width:768px){
          flex-direction: column;
        }
      }
    }
    .container{
      display:flex;
      align-items: center;
      justify-content: space-between;
      @media(max-width:768px){
        flex-direction: column;
      }
      .bloc-txt{
        max-width:calc(50% - 30px);
        width:100%;
        @media(max-width:768px){
          max-width:calc(100%);
          order: 2;
          margin-top:30px;
        }
        div.picto{
          border-radius: 50%;
          width:50px;
          height:50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $purple;
          margin-bottom:20px;
          padding:10px;
          img{
            width:auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        p.title{
          font-size:30px;
          line-height:38px;
          font-weight: 600;
          margin-bottom:15px;
        }
        a.btn-inline{
          margin-top:30px;
        }
      }
      .bloc-img{
        max-width:calc(50% - 30px);
        width:100%;
        display:flex;
        justify-content: flex-end;
        @media(max-width:768px){
          max-width:calc(100%);
          order: 1;
        }
        img{
          max-width: 100%;
          max-height: 100%;
          width:auto;
          height:auto;
        }
      }
    }
  }
}
