/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 60px 0px 0px 0px;
  @media all and (max-width: 767px) {
    padding: 60px 0px 10px 0px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1310px;
    margin: auto;
    @media all and (max-width: 992px) {
      display: initial;
    }

    .logo {
      flex: 0.7;
      @media all and (max-width: 500px) {
        max-width: 150px;
        height: auto;
      }
      .item-logo {
        display: block;
        svg {
          margin: auto;
          display: block;
        }
      }
    }


	img.footer-icon-youtube {
		width:30px;height:30px;
	}
    .description{
      margin-top:20px;
      margin-bottom:30px;
      max-width:320px;
      font-size:16px;
    }
    .item-nav {
      display: flex;
      flex: 2;
      gap:0px 32px;
      @media all and (max-width: 992px) {
        flex-wrap: wrap;
        gap:10px 40px;
      }

      ul {
        margin: 0px;
        @media all and (max-width: 992px) {
          width: calc(50% - 20px);
          margin: 0px;
          float: left;
        }

        li {
          margin: 0px 0;
        }
        @media all and (max-width: 992px) {
          &:nth-of-type(2n+1) {
            clear: both;
          }
        }
      }

      .ul-flex {
        display: flex;

        ul {
          margin: 0;

          li:first-child {
            margin-top: 0;
          }

          &:first-child {
            margin-right: 40px;
          }
        }
        @media all and (max-width: 645px) {
          justify-content: space-between;

          ul {
            flex: 1;

            &:last-child {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
        @media all and(max-width: 400px) {
          flex-direction: column;
        }
      }
      //@media all and (max-width: 645px) {
      //  flex-wrap: wrap;
      //  justify-content: space-between;
      //
      //  ul:last-child {
      //    margin-left: 0;
      //    flex: 1;
      //  }
      //
      //  ul:nth-of-type(1) {
      //    max-width: 100%;
      //    width: 100%;
      //  }
      //
      //  ul:nth-of-type(2) {
      //    margin-right: 40px;
      //    flex: 1;
      //  }
      //}
      @media all and (max-width: 362px) {
        ul {
          margin-left: 0 !important;
        }
      }
    }
    @media all and (max-width: 645px) {
      flex-direction: column;
    }
  }

  .footer-bottom {
    clear: both;
    max-width: 1310px;
    margin: auto;
    padding:32px 0px;
    margin-top:65px;
    border-top: 1px solid #000;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 645px) {
      margin-top:32px;
      flex-direction: column;
    }
    ul{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:0px 5px;
      @media all and (max-width: 645px) {
        flex-direction: column;
        &.socials{
          flex-direction: row;
          justify-content: center;
          margin-top:30px;
        }
      }
    }
    ul li {
      display: inline-block;
      font-size: 16px;
      margin: 0px;
      @media all and (max-width: 645px) {
        font-size:14px;
      }
      *{
        color:#000;
      }
    }
  }
  @media all and (max-width: 575px) {
    padding: 20px 30px 35px;
  }

  .socials {
    display:flex;
    gap:10px 22px !important;
    li{
      a{
        display:block;
        width:25px;
        height:25px;
        text-indent: -9999px;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: #000;
        transition: 0.5s;
        &:hover{
          background-color: $purple;
        }
        &.twitter{
          mask-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.55016 19.75C16.6045 19.75 21.5583 12.2468 21.5583 5.74192C21.5583 5.53098 21.5536 5.31536 21.5442 5.10442C22.5079 4.40752 23.3395 3.54432 24 2.55536C23.1025 2.95466 22.1496 3.21544 21.1739 3.3288C22.2013 2.71297 22.9705 1.74553 23.3391 0.605828C22.3726 1.17862 21.3156 1.58267 20.2134 1.80067C19.4708 1.01162 18.489 0.489177 17.4197 0.314112C16.3504 0.139047 15.2532 0.321111 14.2977 0.832157C13.3423 1.3432 12.5818 2.15477 12.1338 3.14137C11.6859 4.12798 11.5754 5.23468 11.8195 6.29036C9.86249 6.19215 7.94794 5.68377 6.19998 4.79816C4.45203 3.91255 2.90969 2.6695 1.67297 1.14958C1.0444 2.2333 0.852057 3.51571 1.13503 4.73615C1.418 5.9566 2.15506 7.02351 3.19641 7.72005C2.41463 7.69523 1.64998 7.48474 0.965625 7.10598V7.16692C0.964925 8.30421 1.3581 9.40665 2.07831 10.2868C2.79852 11.167 3.80132 11.7706 4.91625 11.995C4.19206 12.1932 3.43198 12.2221 2.69484 12.0794C3.00945 13.0575 3.62157 13.913 4.44577 14.5264C5.26997 15.1398 6.26512 15.4807 7.29234 15.5013C5.54842 16.8712 3.39417 17.6142 1.17656 17.6107C0.783287 17.6101 0.390399 17.586 0 17.5385C2.25286 18.9838 4.87353 19.7514 7.55016 19.75Z' fill='black'/%3E%3C/svg%3E");
        }
        &.linkedin{
          mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_107_948)'%3E%3Cpath d='M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_107_948'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}
