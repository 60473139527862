#page-software {
  .section-img {
    .block li {
      position: relative;
      padding-left: 20px;
      margin: 8px 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: $green;
      }
    }
  }

  .red {
    background-color: #EB4A4A;
    color: $white;

    h3 {
      max-width: 590px;
    }
  }

  .container {
    margin: 0 auto;
  }

  .swiper-container {
    max-width: 1130px;
    width: 100%;
    margin-left: auto;
    padding-bottom: 50px;
  }

  .swiper-slide {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    img {
      max-width: 850px;
      width: 100%;
      padding: 0 50px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $green;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url("../img/icon/swiper-icon.svg");

    @media all and (max-width: 575px){
      transform: scale(0.5);
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    @media all and (max-width: 575px){
      transform: rotate(180deg) scale(0.5);
    }
  }

  .container-form{
    max-width: 1060px;
    .gfield{
      position: relative;
    }
    .gfield_required{
      position: absolute;
      top: 34px;
      left: 10px;
    }
    #field_1_14 .gfield_required{
      top: 26px;
      left: 20px;
    }

    #input_1_22, .hidden_label{
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0;
    }
  }
}
