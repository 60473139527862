/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap {
  .gform_fields {
    @extend .row;

    input,
    textarea {
      width: 100%;
      background-color: transparent;
    }
  }
}
/*
|
| Materialize form
|-------------------
*/
.materialize-form {
  .materialize-form-group {
    position: relative;
    margin-bottom: 25px;

    label {
      position: absolute;
      top: 0;
      left: 15px;
    }

    input {
      border: none;
      border-bottom: 1px $black solid;
      color: $white;
      height: 25px;
    }
  }
}
/*
|
| Contact form
|---------------
*/
.contact-form {
  label {
    color: $grey;
  }

  textarea {
    border: 1px $black solid;
    height: 120px;
    padding: 5px 10px;
    color: $white;

    &.placeholder_disabled {
      color: $black;
    }
  }

  .gform_body {
    margin-bottom: 20px;
  }

  .gform_footer {
    text-align: right;
  }

  input[type="submit"] {
    position: relative;
    background-color: transparent;
    border: none;
    color: white;
    padding: 15px;
    transition: all 0.3s ease-out;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }

  .gform_confirmation_message {
    color: $very-light-grey;
  }

  .validation_message {
    margin-top: 5px;
    color: $black;
    font-size: 14px;
  }
}

.container-form {
  max-width: 1310px;
  margin: auto;
  padding: 80px 30px;
  @media all and (max-width: 600px) {
    padding-top: 0;
  }
  .validation_error{
    color: #EB4A4A;
  }
}

.gfield_label{
  opacity: 0;
}

.gform_fields {
  max-width: 1070px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.gfield {
  max-width: 48%;
  width: 100%;

  input,
  select,
  textarea {
    width: 100%;
    padding: 15px 20px;
    border: solid 1px $black;
    border-radius: 4px;

    &::placeholder {
      color: $black;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $white;
    background-image: url("../img/icon/select.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 20px) center;
  }

  #input_1_14_1,
  #input_2_14_1 {
    width: auto;
  }
  @media all and (max-width: 600px) {
    max-width: 100%;
    margin-left: 0 !important;
  }
}

#field_1_23,
#field_2_23{
    display: none;
}

#field_1_14 label,
#field_2_14 label {
  opacity: 0.6;
  font-size: 16px;
  margin-left: 8px;
  margin-bottom: -2px;

  a {
    text-decoration: underline;
  }
}

.full-w {
  max-width: 100%;
}

.gform_button {
  font-size: 19px;
  border: solid 1px $black;
  border-radius: 5px;
  padding: 20px 60px 20px 40px;
  margin-top: 40px;
  display: inline-flex;
  align-items: center;
  background-color: $white;
  background-image: url("../img/icon/arrow.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: calc(100% - 30px) center;
}
