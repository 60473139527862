/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*{
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  //box-sizing: initial;
}

body{
    font-family: $font-family-default;
    color: $black;
    font-size: $font-size-md;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;

    @media all and (max-width: 575px){
      font-size: 16px;
    }
}

.title-xl, .cms h2{
  font-size: 36px;
  font-weight:600;
  line-height:44px;
}

h1, h2, h3, h4, h5, h6{
    @each $property, $value in $default-titles{
        #{$property}: $value;
    }
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

button{
    -webkit-appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
}

img{
  max-width: 100%;
}

a, img, span, button{
    display: inline-block;
}

a{
    color:$default-link-color;
    text-decoration: none;

    &:hover{
        color: $default-link-color-hover;
    }
}
.fll{
  float: left;
}
.txtc{
  text-align:center;
}

ul{
    margin: 0;
    padding: 0;

    li{
        list-style-type: none;
    }
}
.clearfix{
  clear: both;
  width: 100%;
  display: block;
}

.flex{
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.m-a{
  margin:auto;
}

.c-black-opacity {
  color: #475467 !important;
}
