/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.svg#helveticaneuelight') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.svg#helveticaneuemedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.svg#helveticaneuebold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI.eot');
    src: url('../fonts/segoe/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI.woff') format('woff'),
        url('../fonts/segoe/SegoeUI.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI-BoldItalic.eot');
    src: url('../fonts/segoe/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI-BoldItalic.woff') format('woff'),
        url('../fonts/segoe/SegoeUI-BoldItalic.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI-BoldItalic.svg#SegoeUI-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI-Italic.eot');
    src: url('../fonts/segoe/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI-Italic.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI-Italic.woff') format('woff'),
        url('../fonts/segoe/SegoeUI-Italic.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI-Italic.svg#SegoeUI-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI-Bold.eot');
    src: url('../fonts/segoe/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoe/SegoeUI-Bold.woff2') format('woff2'),
        url('../fonts/segoe/SegoeUI-Bold.woff') format('woff'),
        url('../fonts/segoe/SegoeUI-Bold.ttf') format('truetype'),
        url('../fonts/segoe/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
