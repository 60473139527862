#page-softwares {
  .cards-image{
    padding:90px 0px 90px 0px;
    @media(max-width:$size3){
      padding:60px 0px;
    }
    .container{
      .bloc-txt{
        max-width: 768px;
        margin:0 auto;
        margin-bottom:75px;
        h2{
          text-align: center;
          font-size:36px;
          line-height:44px;
          font-weight: 600;
          margin-bottom:60px;
          @media(max-width:$size3){
            font-size:30px;
            line-height:36px;
          }
        }
        p{
          font-size:20px;
          color:#475467;
          text-align: center;
        }
      }
      .bloc-img{
        display:flex;
        justify-content: center;
        img{
          height:auto;
          max-width:100%;
        }
      }
      .blocs{
        display:flex;
        flex-wrap: wrap;
        flex-grow: 3;
        gap:66px 30px;
        justify-content: center;
        margin-top:120px;
        @media(max-width:$size3){
          flex-wrap: wrap;
          flex-direction: column;
          margin-top:60px;
        }
        div.bloc{
          text-align: center;
          max-width:calc(100% / 3 - 20px);
          position: relative;
          padding-top:78px;
          @media(max-width:$size3){
            max-width:100%;
          }
          &:before{
            display:block;
            content:'';
            position: absolute;
            top:0px;
            left:50%;
            transform: translateX(-50%);
            background-color: #796EF9;
            border-radius: 50%;
            width:48px;
            height:48px;
            background-image: var(--picto);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
          }
          p.title{
            font-size:20px;
            font-weight:600;
          }
          div.txt{
            color:#475467;
            font-size:16px;
            line-height:24px;
            margin-bottom:20px;
            margin-top:8px;
          }
        }
      }
    }
  }
  .services {
    padding: 95px 0px;
    overflow: hidden;
    @media(max-width:$size3){
      padding-top:0px;
      padding-bottom:60px;
    }
    div.container{
      .wrapper-txt{
        display:flex;
        justify-content: space-between;
        align-items: flex-start;
        @media(max-width:$size3){
          flex-direction: column;
          gap:30px;
        }
        .bloc-title{
          max-width:768px;
          h2{
            font-size:36px;
            font-weight:600;
            margin-bottom:20px;
            @media(max-width:$size3){
              font-size:30px;
              line-height:36px;
            }
          }
          p{
            color:#475467;
            font-size:20px;
          }
        }
        
      }
      .slider{
        margin-top:65px;
        display:flex;
        width:100%;
        .slide{
          width:385px !important;
          padding:24px;
          height:505px;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          @media(max-width:$size3){
            width:320px!important;
          }
          p.title{
            color:#fff;
            font-weight: 600;
            font-size:28px;
            line-height:34px;
            padding-left:70px;
            position: relative;
            background-repeat: no-repeat;
            background-position: 0px 0px;
            min-width:50px;
            min-height: 50px;
          }
          div.bloc{
            background-color: rgba(#fff,0.3);
            border: 1px solid rgba(#fff,0.5);
            padding:32px 24px;
            p.subtitle{
              color:#fff;
              font-size:30px;
              font-weight:600;
              margin-bottom:15px;
            }
            div.txt{
              *{
                color:#fff;
                font-size:18px;
              }
            }
            a.btn-inline{
              margin-top:30px;
              color:#fff;
              font-weight: 600;
              font-size:16px;
              padding-right:28px;
              background-repeat: no-repeat;
              background-position: 100% 50%;
              background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.833008 9.16665L9.16634 0.833313M9.16634 0.833313H0.833008M9.16634 0.833313V9.16665' stroke='white' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              &:after{
                display:none;
              }
            }
          }
        }
      }
      .wrapper-btns{
        display:flex;
        gap:0px 32px;
        margin-top:32px;
        span.btn{
          display:block;
          text-indent: -9999px;
          border-radius: 50%;
          width:56px;
          height:56px;
          background-color: transparent;
          border:1px solid #EAECF0;
          background-repeat: no-repeat;
          background-position: center;
          &.btn-previous{
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 8H1M1 8L8 15M1 8L8 1' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
          &.btn-next{
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15M15 8L8 1M15 8L8 15' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

}
