/*
|--------------------
|      SECTIONS
|--------------------
*/
.section-img {
  max-width: 1920px;
  padding: 80px 0 130px;

  .container-flex {
    display: flex;
    align-items: flex-end;
    @media all and (max-width: 790px) {
      flex-direction: column;
    }
  }

  .img {
    max-width: 1300px;
  }

  .block {
    z-index: 1;
    background-color: $white;
    padding: 80px 30px 30px 80px;
    max-width: 740px;
    width: 100%;
    margin-bottom: -50px;

    p {
      opacity: 0.6;
    }
    @media all and (max-width: 575px) {
      margin-bottom: 0;
      padding: 15px;
    }
  }

  &.left {
    padding: 80px 60px 130px 0;

    .container-flex {
      margin-right: auto;
      justify-content: flex-start;
    }

    .block {
      margin-left: -25%;
    }
    @media all and (max-width: 790px) {
      padding: 80px 60px 80px 0;
    }
    @media all and (max-width: 500px) {
      padding: 60px 30px !important;
    }
  }

  &.right {
    padding: 80px 0 130px 60px;

    .container-flex {
      margin-left: auto;
      justify-content: flex-end;
    }

    .img {
      order: 2;
    }

    .block {
      z-index: 1;
      order: 1;
      margin-right: -25%;
      padding: 80px 80px 30px 30px;
      @media all and (max-width: 575px) {
        margin-bottom: 0;
        padding: 15px;
      }
    }
    @media all and (max-width: 790px) {
      padding: 80px 0 80px 60px;

      .block {
        margin-right: 0;
      }

      .img {
        order: 1;
      }
    }
    @media all and (max-width: 500px) {
      padding: 60px 30px !important;
    }
  }
}
