//.section-archive-description{
//  .container {
//    padding: 0 30px;
//  }
//}

.section-archive-description{
  @media(max-width:$size3){
    padding:60px 0px !important;
  }
  .container {
    h3{
      font-weight: 600;
    }
    .row{
      justify-content: space-between;
      p{
        color:#475467;
      }
      ul{
        margin-top:70px;
        gap:16px 0px;
        display:flex;
        flex-direction: column;
        @media(max-width:$size3){
          margin-top:30px;
        }
        li{
          padding-left:36px;
          background-repeat: no-repeat;
          background-position: 0px 0px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23796EF9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0964 7.39016L9.93638 14.3002L8.03638 12.2702C7.68638 11.9402 7.13638 11.9202 6.73638 12.2002C6.34638 12.4902 6.23638 13.0002 6.47638 13.4102L8.72638 17.0702C8.94638 17.4102 9.32638 17.6202 9.75638 17.6202C10.1664 17.6202 10.5564 17.4102 10.7764 17.0702C11.1364 16.6002 18.0064 8.41016 18.0064 8.41016C18.9064 7.49016 17.8164 6.68016 17.0964 7.38016V7.39016Z' fill='white'/%3E%3C/svg%3E");
        }
      }
    }
  }
}

.section-news {
  position: relative;
}

section.hero{
  padding-top:45px;
  padding-bottom:130px;
  background-color: #F9FAFB;
  div.container{
    h1{
      font-size:60px;
    }
    .filter-container{
      display: flex;
      gap:10px 50px;
      margin-top:25px;
      flex-wrap: wrap;
      @media(max-width:$size3){
        gap:12px 30px;
      }
      .filter-item{
        color:#475467;
        transition: 0.5s;
        &.active{
          color:$purple;
        }
      }
      
    }
  }
}

section.section-news{
  div.container{
    flex-wrap: wrap;
    .img-contain{
      article{
        a{
          div.item-img{
            background-size: contain !important;
          }
        }
      }
    }
    .container-news{
      flex-wrap: wrap;
      justify-content: space-between;
      gap:65px;
      @media all and (max-width: $size3) {
        gap:35px;
        padding:0px 15px;
      }
      article{
        width:calc(50%);
        max-width:475px;
        @media all and (max-width: $size3) {
          max-width:100%;
          width:100%;
        }
      }
    }
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $green;
  border-color: $green transparent $green transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-news {
  position: relative;
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $green;
  border-color: $green transparent $green transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
