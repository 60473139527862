/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-fixed {
  position: fixed;
  z-index: 9999;
  right: -36px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  background-color: $black;
  padding: 18px 40px;
  color: $white;
  border: none;
  @media all and (max-width: 575px) {
    right: -31px;
    padding: 10px 25px;
  }
}

.btn{
  background-color: $purple;
  padding:12px 20px;
  color:#fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid $purple;
  &:hover{
    color: $purple;
    background-color: transparent;
  }
}

.btn-inline{
  color:$purple;
  font-weight:500;
  background-repeat: no-repeat;
  padding-right:24px;
  position: relative;
  &:after{
    display:block;
    content:'';
    position: absolute;
    right:0px;
    top:8px;
    width:12px;
    height:12px;
    background-color: $purple;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1665 7.99992H12.8332M12.8332 7.99992L6.99984 1.58325M12.8332 7.99992L6.99984 14.4166' stroke='%236941C6' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

.btn-arrow {
  font-size: 19px;
  border: solid 1px $black;
  border-radius: 5px;
  padding: 20px 40px;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 20px;
  }

  @media all and (max-width: 575px){
    font-size: 16px;
    padding: 15px 30px;
  }
}
