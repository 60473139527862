#page-service {
  .description {
    .block li {
      position: relative;
      padding-left: 20px;
      margin: 8px 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: $green;
      }
    }
  }
  @media all and (max-width: 790px) {
    .description {
      padding-bottom: 0 !important;
    }
  }
}