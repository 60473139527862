/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/

/*
|
| Bg img default
|----------------------
|
*/

$bg-img-default : (
    width: 100%,
    background-size: cover,
    background-position: center center,
    background-repeat: no-repeat
);

/*
|-------------------
| Bg img creation
|-------------------
| - How to use: $bg-imgs: map-set-bg-imgs($map, $index, $map-sizes)
| - Generated classes: .bg-img-{size}
|
*/
$bg-imgs: ();
$bg-imgs: map-set-bg-imgs($bg-imgs, 'xl', 900px, (
    'lg': 900px,
    'md': 900px,
    'sm': 900px,
    'xs': 900px
));

$bg-imgs: map-set-bg-imgs($bg-imgs, 'lg', 750px, (
    'lg': 750px,
    'md': 750px,
    'sm': 750px,
    'xs': 750px
));

$bg-imgs: map-set-bg-imgs($bg-imgs, 'md', 600px, (
    'lg': 600px,
    'md': 600px,
    'sm': 600px,
    'xs': 600px
));

$bg-imgs: map-set-bg-imgs($bg-imgs, 'sm', 400px, (
    'lg': 400px,
    'md': 400px,
    'sm': 400px,
    'xs': 400px
));

$bg-imgs: map-set-bg-imgs($bg-imgs, 'xs', 250px, (
    'lg': 250px,
    'md': 250px,
    'sm': 250px,
    'xs': 250px
));