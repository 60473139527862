/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
#header {
  width: 100%;
  position: absolute;
  z-index: 10000;
  top:0px;
  background-color: #F9FAFB;
  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    @media(max-width:$size3){
      justify-content: space-between;
    }
    .item-nav {
      display: none;
      padding-left:70px;
      width:100%;
      .item-menu {
        display: flex;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-right: 32px;
          }

          .item-link {
            @extend .link-white;
            cursor: pointer;
            color:#475467;
            font-size:16px;
            &:hover{
              color:#475467;
            }
            svg{
              margin-left:13px;
              path{
                stroke:#475467;
              }
            }
            @media (max-width: 1330px) {
              font-size: 16px;
            }
          }
        }
      }
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }

    .menu-item {
      position: relative;
      padding: 20px 0;
      &:first-of-type{
        .nav-drop{
          left: 0%;
          transform: translateX(0%);
          &::before {
            left: 60px;
            transform: translateX(0%);
          }
        }
      }
      .nav-drop {
        display: none;
        position: absolute;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100%;
        margin-top: 40px;

        .container-nav-drop {
          display: flex;
          align-items: stretch;
          padding: 20px;
          background-color: $purple;
          flex-direction: column;
          a {
            font-size: 16px;
            color:#fff;
            &:hover{
              color:#fff !important;
            }
          }

          ul {
            padding: 0 40px;
          }

          li {
            white-space: nowrap;
          }
        }

        &:hover {
          display: block;
        }

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -16px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 9.5px 16px 9.5px;
          border-color: transparent transparent $purple transparent;
        }
      }

      &:hover {
        .nav-drop {
          display: block;
        }
      }
      &:last-child{
        margin-left:auto;
      }
      &:last-child a {
        background-color: $orange;
        padding: 12px 20px;
        border-radius: 8px;
        color:#fff !important;
        border: 1px solid $orange;
        font-weight: 600;
        transition: 0.5s;
        &:hover{
          background-color: transparent;
          color:$orange !important;
        }
      }
    }

    .menu-item-has-children:nth-of-type(2) .container-nav-drop {
      display: block;
    }
    @media all and (max-width: 500px) {
      padding-top: 15px;
      padding-bottom: 10px;

      .item-logo {
        max-width: 150px;

        svg {
          max-width: 100%;
        }
      }
    }
    @media all and (max-width: 380px) {
      .item-logo {
        max-width: 120px;

        svg {
          max-width: 100%;
        }
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #000;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  @media all and (max-width: 1199px) {
    &.menu-open {
      position: fixed;
      background-color: $purple;
      z-index: 9999;
    }
  }
}
/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $purple;
  overflow: auto;

  .item-container {
    @extend .container;
    padding-top: 120px;
    padding-bottom: 50px;

    ul {
      & > li {
        font-size: 18px;
        margin: 15px 0;

        a {
          @extend .link-white;
          font-size: 18px;
        }
      }
    }

    .menu-item:nth-of-type(1) .item-link.bold:nth-of-type(1) {
      display: none;
    }

    .menu-item-has-children li.hidden {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
  }

  &.open {
    opacity: 1;
  }
}
