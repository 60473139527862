/*
|--------------------
|       Contact
|--------------------
*/

#page-contact,
#page-software {
  .container-form {
    @media (max-width: 600px) {
      padding-top: 50px;
    }
  }

  .container-agencies {
    margin: auto;
    padding: 80px 30px;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:115px 0px;
    max-width:1245px;
    .row{
      margin:0px;
      gap:115px 0px;
    }
    .agency {
      max-width: 50%;
      width: 100%; 
      flex:inherit;
      background-repeat: no-repeat;
      background-position: 0px 5px;  
      padding-left:40px; 
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11.5C10.6569 11.5 12 10.1569 12 8.5C12 6.84315 10.6569 5.5 9 5.5C7.34315 5.5 6 6.84315 6 8.5C6 10.1569 7.34315 11.5 9 11.5Z' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 21C11 17 17 14.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 14.4183 7 17 9 21Z' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      p.title{
        font-size:20px;
        color:#101828;
        opacity: 1;
        font-weight:500;
        margin-bottom:8px;
      }
      p{
        margin-bottom:10px;
      }
    }
  }

  .gform_fields {
    max-width: 100% !important;
  }

  .container-form {
    .gfield {
      position: relative;
      padding-bottom: 25px;
    }

    .gfield_required {
      position: absolute;
      top: 34px;
      left: 10px;
    }

    #field_1_14 .gfield_required {
      top: 26px;
      left: 20px;
    }

    #input_1_22, .hidden_label {
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0;
    }

    .gfield_error {
      input, textarea, select {
        border-color: #EB4A4A;
      }

      .validation_message {
        color: #EB4A4A;
        font-size: 14px;
      }
    }
  }

  //label {
  //  display: none;
  //}

  .gfield_consent_label {
    display: block;
  }

  .ginput_container_consent {
    input {
      float: left;
      width: 40px;
      margin-top: 9px;
    }
  }

  .gfield_required {
    display: none;
  }

}
