/*
|--------------------
|     PAGE ABOUT
|--------------------
*/
#page-about {

  section.text{
    padding:95px 0px 50px 0px;
    p.fs-md{
      color:#475467;
    }
    p{
      margin:10px 0px;
    }
  }

  .diff {
    @media all and (max-width: $size3) {
      padding:60px 0px !important;
    }
    .row {
      align-items: stretch;
    }
    h2{
      color:#101828;
      @media all and (max-width: $size3) {
        margin-bottom:30px !important;
      }
    }
    .bloc {
      padding: 0px 50px;
      text-align: center;
      height: 100%;
      @media all and (max-width: $size3) {
        padding:0px;
      }
      figure{
        img{
          max-width:50px;
          max-height:50px;
          width:auto;
        }
      }
      p{
        color:#475467!important;
      }
    }
    @media all and (max-width: 410px) {
      padding: 60px 15px 40px 15px !important;

      h2 {
        margin-bottom: 30px !important;
      }

      .bloc {
        padding: 40px 20px;
        
      }
    }
  }

  

  .shareholder {
    padding-bottom:135px !important;
    @media all and (max-width: $size3) {
      padding-left:0px !important;
      padding-right:0px !important;
      padding-top:0px !important;
      padding-bottom:145px !important;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 150px;
      padding-bottom: 30px;
    }

    .text {
      max-width: 630px;
      margin-right: 15px;
      p{
        color:#475467!important;
      }
    }

    .p-a {
      max-width: 216px;
      font-size: 16px;
    }

    .legende-right {
      right: -165px;
      top: 0;
    }

    .legende-left {
      left: -100px;
      bottom: -85px;
    }
    @media all and (max-width: 1253px) {
      .text {
        max-width: 520px;
      }
    }
    @media all and (max-width: 1158px) {
      .container {
        flex-direction: column;
        padding-right: 30px;
        align-items: center;
      }

      .text {
        max-width: inherit;
        margin-bottom: 30px;
      }
    }
    @media all and (max-width: 745px) {
      .container {
        align-items: flex-start;
      }

      .schema {
        margin-left: 50px;
      }

      .legende-left {
        left: -50px;
      }
    }
    @media all and (max-width: 595px) {
      .p-a {
        max-width: 130px;
      }

      .schema {
        padding-top: 80px;
        max-width: 70%;
        padding-bottom: 25px;
      }

      .legende-right {
        right: -90px;
      }
    }
    @media all and (max-width: 454px) {
      .schema {
        padding-top: 110px;

        p {
          font-size: 12px;
        }
      }
    }
    @media all and (max-width: 374px) {
      .legende-right {
        right: -60px;
      }
    }
  }

  .key-numbers {
    position: relative;
    background-color: $orange;
    padding:135px 0px;
    @media all and (max-width: $size3) {
      padding:60px 0px 30px 0px;
    }
    .container {
      h2{
        text-align: left;
        color:#fff;
        margin-bottom:80px;
        @media all and (max-width: $size3) {
          margin-bottom:0px;
        }
      }
      p {
        max-width: 220px;
        margin: auto;
      }
      .row{
        margin:0px;
        @media all and (max-width: $size3) {
          justify-content: space-between;
        }
      }
      .number{
        padding:0px;
        @media all and (max-width: $size3) {
          width:calc(100% / 2 - 10px);
          margin-bottom:30px !important;
        }
        .title-lg{
          font-size:60px !important;
          @media all and (max-width: $size3){
            font-size: 40px !important;
          }
        }
      }
    }
    @media all and (max-width: 575px) {
      h2.mb-lg {
        margin-bottom: 20px !important;
      }

      .container {
        padding: 20px !important;
      }
    }
  }

  section.members{
    background-color: #796EF9;
    padding:135px 0px !important;
    h2{
      //color:#fff;
      margin-top:35px;
    }
    .container-members {
      @extend .container;
      padding: 0 30px;
      .row{
        margin:0px;
        background-color: #fff;
      }
      .member {
        background-color: #fff;
        padding-top:20px;
        padding-bottom:50px;
        @media all and (max-width: $size3) {
          padding-bottom: 30px;
        }
        .item-img {
          @extend .bg-cover;
          max-width: 100%;
          height: 260px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
    a.btn{
      margin-top:65px;
      background-color: #fff;
      color:$purple;
    }
  }
  
  .dotation {
    padding: 95px 0;
    @media all and (max-width: 500px) {
      padding: 45px 0;
    }
  }

  .agencies {
    padding: 95px 0;
    @media all and (max-width: 500px) {
      padding: 45px 0;
    }
    h2{
      margin-bottom:80px;
    }
    .container-agencies {
      margin: auto;
      display: flex;
      flex-wrap: wrap;  
      .grid {
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        gap:115px 0px;
        @media all and (max-width: $size3) {
          gap:40px 0px;
        }
        .agency {
          max-width: 50%;
          width: 100%; 
          background-repeat: no-repeat;
          background-position: 0px 5px;  
          padding-left:40px; 
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11.5C10.6569 11.5 12 10.1569 12 8.5C12 6.84315 10.6569 5.5 9 5.5C7.34315 5.5 6 6.84315 6 8.5C6 10.1569 7.34315 11.5 9 11.5Z' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 21C11 17 17 14.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 14.4183 7 17 9 21Z' stroke='%237F56D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          p.title{
            font-size:20px;
            color:#101828;
            opacity: 1;
            font-weight:500;
            margin-bottom:8px;
          }
          p{
            margin-bottom:10px;
          }
        }
        @media all and (max-width: 838px) {
          margin-left: 0;
        }
      }
      
    }
  }

  
}
