#page-news-single {
  .section-article {
    max-width: 1350px;
    padding: 80px 30px;
    margin: auto;

    video {
      max-width: 100%;
    }
    ul {
      padding: 15px 30px 15px 30px;
      li {
        list-style-type: initial;
        ul {
          padding: 15px 30px 15px 30px;
          list-style: disc;
          li {
            list-style-type: initial;
          }
        }
      }
    }
  }

  .btn-arrow.pdf {
    color: #ffffff;
    border-color: #ffffff;
    background-color: $green;
  }
}

#page-cpt-single-partner {
  .section-txt-img{
    padding:95px 0px;
    @media all and (max-width: 875px) {
      padding:60px 0px;
    }
    .container{
      display:flex;
      justify-content: space-between;
      @media all and (max-width: 875px) {
        flex-direction: column;
      }
      .bloc-txt{
        max-width:calc(50% - 30px);
        width:100%;
        @media all and (max-width: 875px) {
          max-width: 100%;
        }
        a.btn{
          margin-top:30px;
        }
      }
      div.bloc-img{
        max-width:calc(50% - 30px);
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        @media all and (max-width: 875px) {
          max-width: 100%;
          margin-top:30px;
        }
      }
    }
  }
  
}

#page-cpt-single {
  .container-content {
    max-width: 910px;
    padding: 0 30px;
    margin: auto;
  }

  .section-orange {
    background-color: #FF9031;
    padding: 40px 0 80px 0;

    .container {
      padding: 0 30px;

      .d-f {
        justify-content: space-between;

        @media all and (max-width: 875px) {
          flex-direction: column;

          .text {
            margin-left: 0 !important;
            margin-top: 20px;
          }
        }
      }
    }

    .text {
      max-width: 630px;

      p:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .section-quote {
    background-color: #08975d;
    color: #fff;

    .logo {
      max-height: 90px;
    }
  }
}
