/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F9FAFB;
  padding-top:45px;
  padding-bottom:130px;
  @media(max-width:$size3){
    padding-bottom:60px;
    padding-top:60px;
  }
  .pb-0{
    padding-bottom:0px;
  }
  .item-content {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    .wrapper-title{
      h1{
        color:#101828;
        max-width: 100%;
        font-size:60px;
        line-height:72px;
        font-weight:600;
        @media(max-width:$size3){
          font-size:40px;
          line-height: 50px;
        }
      }
    }
    .wrapper-text-img{
      display:flex;
      justify-content: space-between;
      @media(max-width:$size3){
        flex-direction: column;
      }
      .bloc-txt{
        max-width: 520px;
        padding-top:25px;
        @media(max-width:$size3){
          max-width: 100%;
        }
        p {
          color:#475467;
        }
        @media all and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
    
          p {
            margin-left: 0;
          }
        }
        a.btn{
          margin-top:50px;
          @media(max-width:$size3){
            margin-top:30px;
          }
        }
      }
    }
    .bloc-img{
      max-width:650px;
      img{
        max-height:400px;
      }
      @media(max-width:$size3){
        max-width: 100%;
        margin-top:30px;
        img{
          max-height:250px;
        }
      }
    }      
  }
  &.banner-default {
        .overlay {
      display: none;
    }
    .container {
      position: relative;
      display: block;
      p {
        max-width: 630px;
        margin-left: 0;
      }
    }
    .btn-back {
      position: absolute;
      left: 10px;
      top: -120px;
      color: $white;
      padding: 20px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }
    .date {
      text-transform: uppercase;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.banner-xs {
  background-color: #fff;
  padding: 80px 30px;

  h2 {
    max-width: 850px;
    margin: auto;
  }
  p {
    margin-bottom: 40px;
  }
  @media all and (max-width: 425px) {
    .title-xl {
      font-size: 32px;
    }
  }
}

/*
|
| Container-news
|--------------
*/

// .section-news {
//   max-width: 1350px;
//   margin: 0 auto;
//   padding: 0 30px;
// }

.new.first .custom-card a {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .item-img {
    max-width: 520px;
    height: 435px;
    margin-right: 30px;
  }

  @media all and (max-width: 991px) {
    .item-img {
      flex: 1;
      margin-right: 15px;
    }
    .item-content {
      flex: 1;
    }
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    .item-img {
      flex: inherit;
      height: 380px;
      margin-right: 0;
      max-width: 100%;
    }
  }
}

/*
|
| Custom card
|--------------
*/
.custom-card {
  a {
    width: 100%;
  }
  .item-img {
    @extend .bg-cover;
    width: 100%;
    height:437px;
    background-size: cover !important;
  }
  .item-content {
    padding: 20px 10px;
    background-color: $white;
    p {
      opacity: 0.6;
    }
  }
  .date {
    opacity: 0.6;
  }
}

.content-cpt-single {
  a {
    text-decoration: underline;
  }
}

.agency {
  p {
    opacity: 0.6;
  }
}

table {
  border-collapse: collapse;
  td {
    border: 1px solid #000000;
    padding: 5px;
  }
}

.img-gamme {
  max-width: 80px;
  float: left;
  margin-right: 20px;
}

.swiperlogo {
  max-width: 100% !important;
  padding-bottom: 0 !important;
  img {
    max-width: initial !important;
    padding: 0 20px !important;
    max-height: 100px;
    width: initial !important;
  }
}

.swiperimg {
  .swiper-slide {
    height: 680px;
    @media all and (max-width: 1200px) {
      height: 450px;
    }
    @media all and (max-width: 992px) {
      height: 290px;
    }
    @media all and (max-width: 500px) {
      height: 250px;
    }
    img {
      max-width: 100% !important;
      width: initial !important;
      height: -webkit-fill-available;
      padding: 0 !important;
    }
  }
}

.opacity0 {
  opacity: 0;
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
  .item-accept{
    background-color: $purple;
    padding: 12px 20px;
    border-radius: 8px;
    color: #fff !important;
    border: 1px solid $purple;
    font-weight: 600;
    transition: 0.5s;
    &:hover{
      background-color: transparent;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 30px;
  }

  h1 {
    font-size:60px;
    line-height:72px;
  }

  h2 {
    @extend .title-xl;
  }
  h3 {
    @extend .title-lg;
  }
  h4 {
    @extend .title-md;
  }
  h5 {
    @extend .title-sm;
  }
  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }
}

.container-content {
  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
      padding-bottom: 5px;
    }
  }
}
